import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createClient } from 'graphql-ws'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'

import { ApolloLink, concat, split } from 'apollo-link'
import { getMainDefinition } from '@apollo/client/utilities'

import env from '../vue-app-env.json'

// SOURCES
// https://community.apollographql.com/t/subscriptions-websocket-connection-to-ws-localhost-4000-graphql-failed/3439/2

const graphqlEndpoint = () => {
  const { VUE_APP_ENV, NODE_ENV } = process.env

  if (VUE_APP_ENV && env[VUE_APP_ENV]) {
    return env[VUE_APP_ENV]
  }

  if (NODE_ENV === 'development') {
    return env.staging
  }
  return env.production
}

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: graphqlEndpoint().graphql_url
})

const wsClient = createClient({
  url: graphqlEndpoint().ws_endpoint,
  connectionParams: () => {
    const token = localStorage.getItem('token')
    // console.log('token exists?', !!token)
    return { authorization: token ? `Bearer ${token}` : '' }
  }
})

// Create the subscription websocket link
const wsLink = new GraphQLWsLink(wsClient)

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
    }
  })
  return forward(operation)
})

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, link),
  cache: new InMemoryCache()
})

// Install the vue plugin
Vue.use(VueApollo)

export function createProvider (options = {}) {
  return new VueApollo({ ...options, defaultClient: apolloClient })
}
