<template>
  <div class="wrapper full-screen">
    <div class="main-panel">
      <v-app>
        <v-app-bar app>
          <div class="d-flex align-center">
            <router-link to="/">
              <v-img
                alt="Smartx Logo"
                class="shrink mr-2"
                contain
                :src="require('./assets/morningstar-600px.png')"
                transition="scale-transition"
                width="130"
              />
            </router-link>
          </div>

          <v-spacer />

          <template v-if="isLoggedIn">
            <v-btn
              title="Logout"
              light
              :loading="loggingIn"
              @click="logOutUser"
            >
              Logout
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              title="Login"
              light
              :loading="loggingIn"
              @click="logInUser"
            >
            Login
            </v-btn>
          </template>
        </v-app-bar>

        <v-main>
          <router-view />
        </v-main>

        <app-footer />
      </v-app>
    </div>

    <notifications
      group="success"
      position="bottom right"
      classes="vue-notification"
    />

    <notifications
      group="warn"
      position="bottom right"
      classes="vue-notification notify-warn-toast"
    />

    <notifications
      group="error"
      position="bottom right"
      classes="vue-notification notify-errors-toast"
    />
  </div>
</template>

<script>
import AppFooter from './components/general/AppFooter.vue'
import '@hedgeco/vaadin-imports'

export default {
  components: { AppFooter },
  name: 'App',
  data () {
    return {
      isLoggedIn: false
    }
  },
  computed: {
    loggingIn: {
      get () {
        return this.$store.state.clientPortal.loggingIn
      },
      set (v) {
        this.$store.commit('clientPortal/setLoggingIn', v)
      }
    }
  },
  async mounted () {
    this.isLoggedIn = await this.$auth.isLoggedIn()
    this.loggingIn = false
  },
  methods: {
    logInUser () {
      this.loggingIn = true
      this.$auth.login()
    },
    logOutUser () {
      this.$auth.logout()
    }
  }
}
</script>

<style>
  .vue-notification {
    font-family: 'Helvetica', sans-serif;
  }
  .notify-warn-toast {
    background: #ffb648 !important;
    border-left-color: #f48a06 !important;
  }
  .notify-errors-toast {
    background: #E54D42 !important;
    border-left-color: #B82E24 !important;
  }
</style>
