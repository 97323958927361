const clientPortal = {
  namespaced: true,
  state: {
    loggingIn: false,
    username: '',
    token: null,
    roles: []
  },
  mutations: {
    setLoggingIn (state, nextState) {
      state.loggingIn = nextState
    },
    setUsername (state, nextState) {
      state.username = nextState
    },
    storeToken (state, nextState) {
      state.token = nextState
    },
    setRoles (state, nextState) {
      state.roles = nextState
    }
  }
}

export default clientPortal
