
export default {
  install (Vue, { axios, store }) {
    if (!store) {
      console.error('Requires a store instance')
    }

    axios.interceptors.request.use((config) => {
      const AUTH_TOKEN = localStorage.getItem('token')
      config.headers.Authorization = `Bearer ${AUTH_TOKEN}`
      config.baseURL = store.getters.api
      return config
    }, (error) => {
      console.error(error)
      return Promise.reject(error)
    })

    Vue.http = Vue.prototype.$http = axios
  }
}
