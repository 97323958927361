<template>
  <div
    class="d-flex flex-column align-center justify-center fill-height my-4"
  >
    <h1>Unauthorized</h1>
    <p style="font-size: 24px;">We're sorry. You don't have access to that page.</p>
      <v-img
        alt="Unauthorized"
        class="shrink"
        contain
        :src="require('@/assets/unauthorized-artwork.png')"
        transition="scale-transition"
        width="802"
      />
  </div>
</template>

<script>
export default {
  name: 'Unauthorized'
}
</script>
