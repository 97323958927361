
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

import Oidc from 'oidc-client'
import LogRocket from 'logrocket'
Oidc.Log.logger = console

const { UserManager, WebStorageStateStore } = Oidc

const { VUE_APP_IDS_AUTHORITY, VUE_APP_CLIENT_ID, VUE_APP_CLIENT_SECRET } = process.env

const STS_DOMAIN = VUE_APP_IDS_AUTHORITY || 'https://ids.svc.qa.smartx.us/'

const redirect_uri = `${window.location.origin}/callback.html`

const silent_redirect_uri = `${window.location.origin}/silent-renew.html`

export default function () {
  // console.log('redirect_uri:', redirect_uri)
  // console.log('silent_redirect_uri:', silent_redirect_uri)
  // console.log('client_secret', VUE_APP_CLIENT_SECRET)

  const settings = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: STS_DOMAIN,
    client_id: VUE_APP_CLIENT_ID || 'smartx-modular-client-web-app',
    client_secret: VUE_APP_CLIENT_SECRET,
    redirect_uri,
    automaticSilentRenew: true,
    silent_redirect_uri,
    response_type: 'id_token token',
    scope: 'openid profile smartx_profile api1',
    post_logout_redirect_uri: window.location.origin,
    filterProtocolClaims: true,
    loadUserInfo: true,
    revokeAccessTokenOnSignout: true,
    accessTokenExpiringNotificationTime: 10,
    silentRequestTimeout: 10000
  }

  const userManager = new UserManager(settings)

  userManager.events.addAccessTokenExpiring(() => {
    // console.log('token expiring')
    userManager.events.addSilentRenewError((e) => console.error(e))
  })

  return {
    userManager,
    addUserLoaded: userManager.events.addUserLoaded,
    getUser: () => userManager.getUser(),
    login: () => userManager.signinRedirect(),
    logout: () => {
      localStorage.removeItem('token')
      userManager.signoutRedirect()
    },
    isLoggedIn: async () => {
      const user = await userManager.getUser()
      const activeUserFound = user !== null && !user.expired

      if (activeUserFound && user?.access_token) {
        localStorage.setItem('token', user.access_token)
        LogRocket.identify(user.profile.smartx_user_guid, {
          name: user.profile.preferred_username ?? user.profile.name,
          email: user.profile.email,
          firstName: user.profile.firstName,
          lastName: user.profile.lastName,
          url: window.location.href
        })
      }
      return activeUserFound
    }
  }
}
