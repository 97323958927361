import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        contrast: '#000000',
        accent: '#FFFFFF',
        highlighted: '#bbdefb',
        accountFlag: '#ffcdd2',
        accountFlagIcon: '#F44336',
        symbolFlag: '#ffe0b2',
        accountFlagAccent: '#A0BBE6'
      },
      dark: {
        contrast: '#FFFFFF',
        accent: '#000000',
        highlighted: '#2196F3',
        accountFlag: '#F44336',
        accountFlagIcon: '#ffcdd2',
        symbolFlag: '#FBC02D',
        accountFlagAccent: '#ffcdd2'
      }
    }
  },
  icons: {
    iconfont: 'md',
    values: {
      editOutline: 'create',
      calendarMonth: 'event',
      checkCircle: 'check_circle',
      chevronLeft: 'chevron_left',
      chevronRight: 'chevron_right',
      information: 'info',
      filterList: 'filter_list',
      openInNew: 'open_in_new',
      arrowLeft: 'arrow_back',
      calendar: 'event_note',
      clock: 'schedule',
      dotsVert: 'more_vert',
      dotsHoriz: 'more_horiz',
      viewList: 'view_list',
      refresh: 'refresh',
      history: 'history',
      cancel: 'cancel',
      minus: 'remove',
      close: 'close',
      alert: 'alert',
      undo: 'undo',
      check: 'check',
      plus: 'add'
    }
  }
})
