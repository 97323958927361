<template>
  <apm-trade-manager :color="color" />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
