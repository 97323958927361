import AuthService from '@/services/authService'

const authService = {
  install (Vue, { router }) {
    const auth = AuthService()

    const validateUser = async (to, from, next) => {
      const user = await auth.getUser()
      const userRoles = user?.profile?.role || []

      if (to.matched.some(route => route.meta.requiresAuth)) {
        if (
          (to.name !== 'Unauthorized' && to.name !== 'Page Not Found') &&
          (!user || user?.expired || !user.access_token)
        ) {
          auth.login()
        } else {
          return !userRoles.includes('Trader') ? next('Unauthorized') : next()
        }
      } else next()
    }

    const setDocumentTitle = (to, from, next) => {
      document.title = to.name ? `${to.name} | SMArtX Trade Dashboard` : 'SMArtX Trade Dashboard'
      next()
    }

    router.beforeEach(validateUser)
    router.beforeEach(setDocumentTitle)

    Vue.prototype.$auth = auth
  }
}

export default authService
