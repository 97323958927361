import Vue from 'vue'
import axios from 'axios'
import lodash from 'lodash'
import VueLodash from 'vue-lodash'
import VueMoment from 'vue-moment'
import Notifications from 'vue-notification'
import UUID from 'vue-uuid'

import App from './App.vue'
import router from './router'
import store from './store'

import { AuthService, BaseHttp, guid, vuetify } from '@/plugins'

import { createProvider } from './vue-apollo'

import SmartxTradeDeskWebApp from '@hedgeco/smartx-trade-desk-web-app'

import LogRocket from 'logrocket'
LogRocket.init('hqtqlf/smartx-enterprise-portal', {
  network: { isEnabled: false }
})

Vue.config.productionTip = false
Vue.use(BaseHttp, { axios, store })
Vue.use(AuthService, { router })

Vue.use(VueLodash, { lodash })
Vue.use(VueMoment)
Vue.use(Notifications)
Vue.use(UUID)
Vue.use(guid)

Vue.use(SmartxTradeDeskWebApp, { store })

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
