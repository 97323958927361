<template>
  <v-container fluid>
    <v-row>
      <trading-container
        :name="name"
        :padding="padding"
        :color="color"
        :links="links"
      />
    </v-row>

    <auto-logout />

  </v-container>
</template>

<script>
import AutoLogout from '@/components/general/AutoLogout.vue'

export default {
  components: { AutoLogout },
  props: {
    name: {
      type: String,
      default: ''
    },
    padding: {
      type: String,
      default: 'pa-3'
    },
    color: {
      type: String,
      default: 'primary'
    },
    links: {
      type: Array,
      default: () => []
    }
  }
}
</script>
