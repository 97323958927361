<template>
  <div
    class="d-flex flex-column align-center justify-center fill-height my-4"
  >
    <h1>Page Not Found</h1>
    <p style="font-size: 24px;">We're sorry. The page you are looking for does not exist.</p>
      <v-img
        alt="Page Not Found"
        class="shrink"
        contain
        :src="require('@/assets/people-asking-questions-illustration.png')"
        transition="scale-transition"
        width="626"
      />
      <v-btn
        large
        color="primary"
        class="ma-4"
        to="/"
      >
        Return Home
      </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
