import Vue from 'vue'
import Vuex from 'vuex'

import env from '../../vue-app-env.json'

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    tradeDesk: {
      namespaced: true
    },
    ...modules
  },
  state: {
    env
  },
  getters: {
    pointedToLive: (state) => {
      return process.env.NODE_ENV === 'production'
    },
    appEnv (state) {
      const { VUE_APP_ENV, NODE_ENV } = process.env

      if (VUE_APP_ENV && state.env[VUE_APP_ENV]) {
        if (VUE_APP_ENV !== 'staging' && NODE_ENV === 'development') {
          console.error(`WARNING: Might be hitting a production environment!\n\n VUE_APP_ENV: ${VUE_APP_ENV}`)
        }

        return state.env[VUE_APP_ENV]
      }

      if (NODE_ENV === 'development') {
        return state.env.staging
      }
      return state.env.production
    },
    api: (state, getters) => {
      return getters.appEnv.client_api_url
    }
  }
})

export default store
