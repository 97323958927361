<template>
  <v-snackbar
    v-model="userNotification"
    :timeout="-1"
    bottom
    class="snackbar"
  >
    <v-row no-gutters>
      <v-icon
        left
        dense
        color="white"
      >
        error
      </v-icon>
      {{ message || '- -' }}
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      userNotification: false,
      message: null,
      activityEvents: [
        'load',
        'mousemove',
        'click',
        'keydown'
      ],
      idleLogout: null,
      idleWarning: null
    }
  },
  mounted () {
    this.detectIdleUser()
  },
  beforeDestroy () {
    clearTimeout(this.idleWarning)
    this.idleWarning = null
    clearTimeout(this.idleLogout)
    this.idleLogout = null

    this.activityEvents.forEach(event => {
      window.removeEventListener(event, this.resetIdleTimer)
    })
  },
  methods: {
    displayLogoutWarning () {
      this.userNotification = true
      this.message = 'You will soon be logged out due to inactivity'
      // console.log(this.message)
    },
    closeLogoutWarning () {
      this.userNotification = false
      this.message = null
    },
    detectIdleUser () {
      this.activityEvents.forEach(event => {
        window.addEventListener(event, this.resetIdleTimer)
      })
    },
    resetIdleTimer () {
      this.closeLogoutWarning()
      clearTimeout(this.idleWarning)
      clearTimeout(this.idleLogout)

      this.idleWarning = setTimeout(() => {
        this.displayLogoutWarning()
      }, 1500000)

      this.idleLogout = setTimeout(() => {
        this.$auth.logout()
      }, 1800000)
    }
  }
}
</script>
