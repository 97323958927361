<template>
  <trade-monitor-allocations
    :request-id="requestId"
    :color="color"
    />
</template>

<script>
export default {
  props: {
    requestId: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
