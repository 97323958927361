<template>
  <v-footer
    class="footer-styles elevation-5 d-flex"
    fixed
    app
  >
    <div id="copyright" class="px-3 text--secondary">
      &copy; {{ new Date().getFullYear() }} SMArtX Advisory Solutions | Version: {{ appVersion }}
    </div>
    <div id="compliance-links" class="d-flex">
      |
      <a
        title="Privacy Policy"
        href="https://www.smartxadvisory.com/legal-compliance/privacy-policy/"
        target="_blank"
        class="px-3 text--secondary text-decoration-none"
      >
        Privacy Policy
        <v-icon dense>
          open_in_new
        </v-icon>
      </a>
      |
      <a
        title="Terms of Use"
        href="https://www.smartxadvisory.com/legal-compliance/terms-of-use/"
        target="_blank"
        class="px-3 text--secondary text-decoration-none"
      >
        Terms of Use
        <v-icon dense>
          open_in_new
        </v-icon>
      </a>
    </div>
  </v-footer>
</template>

<script>
export default {
  computed: {
    appVersion () {
      return process.env.VUE_APP_VERSION
    }
  }
}
</script>
