
export default {
  install (Vue) {
    // https://stackoverflow.com/a/13653180/2932052

    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

    const isValid = v => v ? !!v.toLowerCase().trim().match(regex) : false

    Vue.prototype.$guid = { isValid }
  }
}
